import { StorageTimestamp } from "./base/data-type";
import { TopicHierarchy, topicHierarchyConverter } from "./topic-hierarchy";
import { getEnumKeyByEnumValue } from "../utils/enum";
import FirestoreDataConverter from "./base/data-converter";

/**
 * NOTE: the string value is used as a display string in various places
 */
export enum LibraryContentType {
  businessStory = "Business Story",
  cultureStory = "Culture Story",
  customerStory = "Customer Story",
  productExperienceMatrix = "Product Experience Matrix",

  productStory = "Product Story",
  article = "Article",
  framework = "Framework",
  talentTool = "Talent Tool",

  interactive = "Interactive", // Microproducts like Hiring Benchmarks, etc.

  topicPage = "Topic", // List of other articles. e.g. People & Hiring
  subtopicPage = "Subtopic", // List of other articles. e.g. Culture, Finance

  playlist = "Playlist",

  sourceCode = "SourceCode", // js, css, etc.

  unknown = "unknown",
}

/** One of the tokens in the variantKey.
 *
 * So library/123/v5/founder_scout is a variantKey with two tokens (LibraryAudienceType): founder and scout.
 * This is the document variant used for users with user.audience=[UserAudience.scout, UserAudience.founder]
 */
export enum LibraryAudienceType {
  founder = "founder",
  leader = "leader",
  scout = "scout",
  ghost = "ghost",
  sequoia = "sequoia",
  unknown = "unknown",
}

/** Whitelist of all possible library variants.
 *
 * Not every combinitoral is possible (e.g. ghost-founder is invalid).
 *
 * Keep in sync with AllAudienceCombinations
 */
export const AllValidLibraryAudienceCombinations: LibraryAudienceType[][] = [
  [LibraryAudienceType.sequoia],
  [LibraryAudienceType.ghost],
  [LibraryAudienceType.founder],
  [LibraryAudienceType.founder, LibraryAudienceType.scout],
  [LibraryAudienceType.founder, LibraryAudienceType.leader],
  [LibraryAudienceType.scout],
  [LibraryAudienceType.scout, LibraryAudienceType.leader],
  [LibraryAudienceType.leader],
];

export class LibraryAuthor {
  /** User Id */
  id?: string;
  name: string;
  type: string;

  title?: string;
  bio?: string;
  image?: string;
  imageBadge?: string;

  url?: string;

  constructor(
    id: string | undefined,
    name: string,
    type: string,
    title?: string,
    bio?: string,
    image?: string,
    url?: string,
    imageBadge?: string,
  ) {
    this.id = id;
    this.name = name;
    this.type = type;

    this.title = title;
    this.bio = bio;
    this.image = image;
    this.url = url;

    this.imageBadge = imageBadge;
  }
}

export type PlaylistContentMeta = { id: string; title: string; link: string };
export class LibraryEntry {
  id: string;
  title: string;
  author: string;
  authorData?: LibraryAuthor[];
  createdAt: StorageTimestamp;
  updatedAt?: StorageTimestamp;
  contentType: LibraryContentType;
  shortDescription?: string;

  audiences?: LibraryAudienceType[];
  body?: string;
  bodyFull?: string;

  trackingId?: string;

  like?: { [key: string]: Date }; // deprecated, see `library_reaction` in firestore
  helpful?: { [key: string]: Date }; // deprecated, see `library_reaction` in firestore
  empathize?: { [key: string]: Date }; // deprecated, see `library_reaction` in firestore
  view?: string[];
  viewable?: string[];
  numComments?: number;

  unlisted?: boolean;
  topics?: string[];
  dependencies?: string[];

  // For playlists only
  playlistContentMeta?: PlaylistContentMeta[];

  // Used for indexing hierarchical Algolia search.  Right now only generates
  // one entry per array, but may eventually contain more than one if
  // we rework the NavTree to support that.
  topicHierarchy?: TopicHierarchy;

  featureImage?: string;

  constructor(
    id: string,
    title: string,
    author: string,
    contentType: LibraryContentType,
    createdAt: StorageTimestamp,
    updatedAt?: StorageTimestamp,
    audience?: LibraryAudienceType[],
    authorData?: LibraryAuthor[],
    shortDescription?: string,
    body?: string,
    bodyFull?: string,
    trackingId?: string,
    viewable?: string[],
    like?: { [key: string]: any },
    helpful?: { [key: string]: any },
    empathize?: { [key: string]: any },
    view?: string[],
    numComments?: number,
    unlisted?: boolean,
    topics?: string[],
    dependencies?: string[],
    playlistContentMeta?: PlaylistContentMeta[],
    topicHierarchy?: TopicHierarchy,
    featureImage?: string,
  ) {
    this.id = id;
    this.title = title;
    this.author = author;
    this.authorData = authorData;
    this.contentType = contentType;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.audiences = audience;
    this.shortDescription = shortDescription;
    this.body = body;
    this.bodyFull = bodyFull;
    this.trackingId = trackingId;
    this.like = like;
    this.helpful = helpful;
    this.empathize = empathize;
    this.view = view;
    this.viewable = viewable;
    this.numComments = numComments;
    this.unlisted = unlisted;
    this.topics = topics;
    this.dependencies = dependencies;
    this.playlistContentMeta = playlistContentMeta;
    this.topicHierarchy = topicHierarchy;
    this.featureImage = featureImage;
  }
}

const LibraryAuthorDataConverter: FirestoreDataConverter<LibraryAuthor> = {
  toFirestoreModel: function (libraryAuthor: LibraryAuthor) {
    return {
      id: libraryAuthor.id,
      name: libraryAuthor.name,
      type: libraryAuthor.type,
      title: libraryAuthor.title,
      bio: libraryAuthor.bio,
      image: libraryAuthor.image,
      url: libraryAuthor.url,
      image_badge: libraryAuthor.imageBadge,
    };
  },
  fromFirestoreModel: function (data): LibraryAuthor {
    return new LibraryAuthor(
      data.id,
      data.name,
      data.type,
      data.title,
      data.bio,
      data.image,
      data.url,
      data.image_badge,
    );
  },
};

export const libraryEntryConverter: FirestoreDataConverter<LibraryEntry> = {
  toFirestoreModel: function (libraryEntry: LibraryEntry) {
    const authorData = libraryEntry.authorData
      ? libraryEntry.authorData.map((d) => {
          return LibraryAuthorDataConverter.toFirestoreModel(d);
        })
      : undefined;
    const topicHierarchyData = libraryEntry.topicHierarchy
      ? topicHierarchyConverter.toFirestoreModel(libraryEntry.topicHierarchy)
      : undefined;

    return {
      id: libraryEntry.id,
      title: libraryEntry.title,
      author: libraryEntry.author,
      content_type: libraryEntry.contentType,
      created_at: libraryEntry.createdAt,
      updated_at: libraryEntry.updatedAt,
      audience: libraryEntry.audiences,
      author_data: authorData,
      short_description: libraryEntry.shortDescription,
      body: libraryEntry.body,
      body_full: libraryEntry.bodyFull,
      tracking_id: libraryEntry.trackingId,
      viewable: libraryEntry.viewable,
      like: libraryEntry.like,
      helpful: libraryEntry.helpful,
      empathize: libraryEntry.empathize,
      view: libraryEntry.view,
      num_comments: libraryEntry.numComments,
      unlisted: libraryEntry.unlisted,
      topic: libraryEntry.topics,
      dependencies: libraryEntry.dependencies,
      playlist_content_meta: libraryEntry.playlistContentMeta,
      topic_hierarchy: topicHierarchyData,
      feature_image: libraryEntry.featureImage,
    };
  },
  fromFirestoreModel: function (data): LibraryEntry {
    const authorData = data.author_data
      ? data.author_data.map((d: { [field: string]: any }) =>
          LibraryAuthorDataConverter.fromFirestoreModel(d),
        )
      : undefined;
    const audiences = (data.audience as string[] | undefined)?.map(
      (a) =>
        LibraryAudienceType[
          getEnumKeyByEnumValue(LibraryAudienceType, a) ??
            LibraryAudienceType.unknown
        ],
    );
    const topicHierarchy = data.topic_hierarchy
      ? topicHierarchyConverter.fromFirestoreModel(data.topic_hierarchy)
      : undefined;

    return new LibraryEntry(
      data.id,
      data.title,
      data.author,
      LibraryContentType[
        getEnumKeyByEnumValue(LibraryContentType, data.content_type) ??
          LibraryContentType.unknown
      ],
      data.created_at,
      data.updated_at,
      audiences,
      authorData,
      data.short_description,
      data.body,
      data.body_full,
      data.tracking_id,
      data.viewable,
      data.like,
      data.helpful,
      data.empathize,
      data.view,
      data.num_comments,
      data.unlisted,
      data.topic,
      data.dependencies,
      data.playlist_content_meta,
      topicHierarchy,
      data.feature_image,
    );
  },
};
