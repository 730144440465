import { StorageTimestamp } from "./base/data-type";
import { getEnumKeyByEnumValue } from "../utils/enum";
import FirestoreDataConverter from "./base/data-converter";

export enum ReactionType {
  helpful = "helpful",
  notHelpful = "nothelpful",
  unknown = "unknown",
}

export class LibraryReaction {
  id: string;
  createdAt: StorageTimestamp;
  createdById: string;
  libraryId: string;
  reactionType: ReactionType;

  constructor(
    id: string,
    createdAt: StorageTimestamp,
    createdById: string,
    libraryId: string,
    reactionType: ReactionType,
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.createdById = createdById;
    this.libraryId = libraryId;
    this.reactionType = reactionType;
  }
}

export const libraryReactionDataConverter: FirestoreDataConverter<LibraryReaction> =
  {
    toFirestoreModel: function (reaction: LibraryReaction) {
      return {
        id: reaction.id,
        created_at: reaction.createdAt,
        created_by_id: reaction.createdById,
        library_id: reaction.libraryId,
        reaction_type: reaction.reactionType,
      };
    },
    fromFirestoreModel: function (data): LibraryReaction {
      return new LibraryReaction(
        data.id,
        data.created_at,
        data.created_by_id,
        data.library_id,
        ReactionType[
          getEnumKeyByEnumValue(ReactionType, data.reaction_type) ??
            ReactionType.unknown
        ],
      );
    },
  };
