// https://github.com/draft-js-plugins/draft-js-plugins/blob/master/packages/anchor/src/

import mailRegex from "./mailRegex";
import urlRegex from "./urlRegex";

const URLUtils = {
  isUrl(text: string): boolean {
    return urlRegex().test(text);
  },

  isMail(text: string): boolean {
    return mailRegex().test(text);
  },

  normalizeMail(email: string): string {
    if (email.toLowerCase().startsWith("mailto:")) {
      return email;
    }
    return `mailto:${email}`;
  },

  normalizeUrl(url: string | undefined): string | undefined {
    if (!url) {
      return url;
    }
    if (!URLUtils.isUrl(url)) {
      return url;
    }
    if (url.startsWith("http")) {
      return url;
    }
    return `https://${url}`;
  },

  safeUrl(url?: string): URL | undefined {
    if (!url || !URLUtils.isUrl(url)) {
      return undefined;
    }
    try {
      return new URL(url);
    } catch (e) {
      return undefined;
    }
  },

  safeUrlWithBase(
    url: string | null | undefined,
    base: string | null | undefined,
  ): URL | undefined {
    if (!url) {
      return undefined;
    }
    try {
      return new URL(url, base || "");
    } catch (e) {
      return undefined;
    }
  },
};

export default URLUtils;
