import { User, UserAudience } from "../models/user";
import { exists } from "./exists";

export function firstName(user: User | string) {
  if (typeof user === "string") {
    return user.split(" ")[0] || user || "";
  }
  return user.name.split(" ")[0] || user.name || "";
}

export function firstNamePossessive(user: User) {
  const name = firstName(user);
  let firstNamePosessive: string | null = null;
  if (name[name.length - 1] === "s") {
    firstNamePosessive = `${name}'`;
  } else {
    firstNamePosessive = `${name}'s`;
  }
  return firstNamePosessive;
}

export function generateAbout(user: User) {
  return [user.companyName, user.location].filter(exists).join(", ");
}

export function fullNamePossessive(user: User) {
  const name = user.name;
  let firstNamePosessive: string | null = null;
  if (name[name.length - 1] === "s") {
    firstNamePosessive = `${name}'`;
  } else {
    firstNamePosessive = `${name}'s`;
  }
  return firstNamePosessive;
}

export function getLinkedInSlug(url: string) {
  const parts = url.replace("https://", "").replace("http://", "").split("/");
  if (parts.length === 1) {
    return decodeURIComponent(parts[0]);
  }

  if (parts.length < 3) {
    return null;
  }

  if (!parts[0].endsWith("linkedin.com")) {
    return null;
  }
  return decodeURIComponent(parts[2]);
}

export function meFirst(myId: string) {
  return (a: { createdById: string }, b: { createdById: string }) => {
    if (a.createdById === myId) {
      return -1;
    }
    if (b.createdById === myId) {
      return 1;
    }
    return 0;
  };
}

/** Everyone alphabetical, sequoia at end */
export function sortLexiSequoiaAtEnd(a: User, b: User) {
  return sequoiaTypeAtEndCompare(a, b) || a.name.localeCompare(b.name);
}

/** Incomplete sort - if values are the same, returns undefined */
function sequoiaTypeAtEndCompare(a: User | undefined, b: User | undefined) {
  if (a === b) {
    return undefined;
  }
  return sequoiaTypeAtEnd(a) - sequoiaTypeAtEnd(b);
}

/** non-sequoia, then missing audience, then sequoia */
function sequoiaTypeAtEnd(user: User | undefined) {
  if (!user?.audience?.length) {
    return 9;
  }
  if (isSequoiaUser(user)) {
    return 10;
  }
  return -1;
}

/**
 * NOTE: excludes Sequoia users
 */
export function isScoutUser(user: User | undefined): boolean {
  if (!user?.audience) {
    return false;
  }
  return (
    user.audience.includes(UserAudience.scout) &&
    !user.audience.includes(UserAudience.sequoia)
  );
}

/**
 * NOTE: excludes Sequoia users
 */
export function isFounderUser(user: User | undefined): boolean {
  if (!user?.audience) {
    return false;
  }
  return (
    user.audience.includes(UserAudience.founder) &&
    !user.audience.includes(UserAudience.sequoia)
  );
}

/**
 * NOTE: excludes Sequoia users
 */
export function isLeaderUser(user: User | undefined): boolean {
  if (!user?.audience) {
    return false;
  }
  return (
    user.audience.includes(UserAudience.leader) &&
    !user.audience.includes(UserAudience.sequoia)
  );
}

/**
 * NOTE: this checks the _User_ document (audience) so Sequoia ghosts will be "false"
 * See isSequoiaAuth for checking the logged in user's email (for Sequoia ghosts)
 */
export function isSequoiaUser(user: User | undefined): boolean {
  if (!user?.audience) {
    return false;
  }
  return user.audience.includes(UserAudience.sequoia);
}

/**
 * NOTE: excludes Sequoia users
 */
export function isBuilderUser(user: User | undefined): boolean {
  if (!user?.audience) {
    return user?.builderInfo !== undefined;
  }
  // Don't know if we'll ever have sequoia builders, but for now make the explicity
  // choice that sequoia users are not builders.
  return (
    user.builderInfo !== undefined &&
    !user.audience.includes(UserAudience.sequoia)
  );
}
