import { useAPIGetServerProp } from "~/network/user-api";
import useFCM from "~/network/useFCM";

import { UserAudience } from "@sequoiacap/shared/models";
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from "~/utils/localStorage";
import useUserInfo from "~/network/useUserInfo";

const MUTE_PUSHNOTIF_TOAST = "MUTE_PN_PROMPT";
const MUTE_DURATION = 2 * 24 * 60 * 60 * 1000; // 2 days

export function useNotificationPermissionToast() {
  const { userId: loggedInUserId, audiences } = useUserInfo();
  const { data: serverProp } = useAPIGetServerProp(loggedInUserId);
  const { notificationStatus, enableNotifications } = useFCM();
  const muteUntil = getLocalStorageValue(MUTE_PUSHNOTIF_TOAST, 0);
  const isPastMuteUntil = new Date() > new Date(muteUntil);
  const message = getMessageBasedOnAudiences(audiences);

  return {
    enableNotificationPermission: enableNotifications,
    showNotificationPermissionToast: Boolean(
      serverProp?.serviceWorkerV2 &&
        notificationStatus === "default" &&
        isPastMuteUntil &&
        message,
    ),

    message,

    /** Will mute for MUTE_DURATION ms. */
    muteNotificationToast: () => {
      setLocalStorageValue(
        MUTE_PUSHNOTIF_TOAST,
        new Date().getTime() + MUTE_DURATION,
      );
    },
  };
}

function getMessageBasedOnAudiences(audiences: UserAudience[] | undefined) {
  if (!audiences?.length) {
    return undefined;
  }
  if (audiences.includes(UserAudience.sequoia)) {
    return "Never miss a relevant post or message.";
  }
  if (audiences.includes(UserAudience.founder)) {
    return "Never miss a relevant post or message from fellow founders.";
  }
  if (audiences.includes(UserAudience.scout)) {
    return "Never miss an update from your investor.";
  }
  if (audiences.includes(UserAudience.leader)) {
    return "Never miss a relevant post or message.";
  }
}
