import { Facets, Hit, PaginatedSearchResult, SearchResult } from "./types";
import { USE_INFINITE_QUERY } from "../firebase/FirebaseAPI";
import { trackSearchTiming } from "~/utils/analytics";
import { useCallback, useEffect, useMemo, useRef } from "react";
import algoliasearchHelper from "algoliasearch-helper";
import useAlgolia from "./useAlgolia";
import useSWRInfinite from "swr/infinite";

export type QueryOptions = algoliasearchHelper.PlainSearchParameters;

type SearchResultPage<T, THit> = {
  data: SearchResult<T, THit>[];
  hasMore: boolean;
  facets?: Facets;
  totalHits?: number;
};

export function useAlgoliaSearch<T, THit = Record<string, unknown>>(
  index: string | null,
  queryOption: QueryOptions,
  processer: (hit: Hit) => Promise<T | null>,
): PaginatedSearchResult<SearchResult<T, THit>> {
  const { prefix, client, refreshToken } = useAlgolia();
  const queryOptionString = useMemo<string>(() => {
    return JSON.stringify(queryOption);
  }, [queryOption]);

  const {
    data,
    error: swrError,
    size,
    setSize,
  } = useSWRInfinite<SearchResultPage<T, THit>>(
    (pageIndex, previousPageData) => {
      if (!client) {
        throw new Error("client not ready");
      }
      // not ready to search
      if (index === null) {
        return null;
      }
      if (previousPageData && !previousPageData.data.length) {
        return null; // reached the end
      }
      return [index, queryOptionString, pageIndex, USE_INFINITE_QUERY];
    },

    async ([fetcherIndex, fetcherQueryOption, pageIndex]: [
      string,
      string,
      number,
    ]) => {
      const searchOptions = JSON.parse(fetcherQueryOption);
      if (!client) {
        throw new Error("client not ready");
      }
      const startSearch = new Date();
      const result: algoliasearchHelper.SearchResults = await new Promise(
        (resolve, reject) => {
          const helper = algoliasearchHelper(
            client,
            `${prefix}${fetcherIndex}`,
            searchOptions,
          );
          helper.setPage(pageIndex);
          helper.search();
          helper.on("result", (event) => {
            resolve(event.results);
            const trackProperties = {
              step: "aglolia_success",
              ms: new Date().getTime() - startSearch.getTime(),
              index,
              queryOptionString,
            };
            trackSearchTiming(trackProperties);
          });
          helper.on("error", (event) => {
            console.error(
              "useAlgoliaSearch/fetch error",
              event.error,
              index,
              queryOption,
            );
            reject(event.error);
            const trackProperties = {
              step: "aglolia_error",
              ms: new Date().getTime() - startSearch.getTime(),
              error: event.error.toString(),
              index,
              queryOptionString,
            };
            trackSearchTiming(trackProperties);

            if (refreshToken) {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              refreshToken();
            }
          });
        },
      );

      const startProcessResults = new Date();
      const parsedResult = (
        await Promise.all(
          result.hits.map(
            async (hit): Promise<SearchResult<T, THit> | null> => {
              const startProcessHit = new Date();
              const processedHit = await processer(hit);
              const trackHitProperties = {
                id: hit.objectID,
                hitType: hit.type,
                step: "process_hit",
                ms: new Date().getTime() - startProcessHit.getTime(),
                success: !!processedHit,
                index,
                queryOptionString,
              };
              trackSearchTiming(trackHitProperties);
              if (processedHit) {
                return {
                  id: hit.objectID,
                  data: processedHit,
                  highlight: hit._highlightResult,
                  snippet: hit._snippetResult,
                };
              }
              return null;
            },
          ),
        )
      ).filter((value): value is SearchResult<T, THit> => {
        return value !== null;
      });

      const trackProperties2 = {
        step: "processing",
        ms: new Date().getTime() - startProcessResults.getTime(),
        msFromStart: new Date().getTime() - startSearch.getTime(),
        numProcessed: result.hits.length,
        index,
        queryOptionString,
      };
      trackSearchTiming(trackProperties2);
      console.log(
        `[algolia] fetched index=${fetcherIndex} pageIndex=${pageIndex} pageSize=${result.hits.length} processed=${parsedResult.length} totalResult=${result.nbHits}`,
      );
      const hasMore = result.nbPages > pageIndex;
      const resultFacets: Facets = {};
      result.disjunctiveFacets.forEach((facet) => {
        resultFacets[facet.name] = facet.data as unknown as Record<
          string,
          number
        >;
      });
      return {
        data: parsedResult,
        hasMore,
        facets: resultFacets,
        totalHits: result.nbHits,
      };
    },
    {
      // NOT ideal, when set to true, requesting page 3, would also refetch page 1 and 2 again
      // but when set to false, when user hits back and there is some data, it would only invalidate the first page
      revalidateAll: false,
      persistSize: true,
    },
  );
  const sizeRef = useRef(size);
  useEffect(() => {
    sizeRef.current = size;
  }, [size]);
  const nextPage = useCallback(async (): Promise<void> => {
    if (setSize) {
      console.log("[algolia] request next page", sizeRef.current);
      await setSize(sizeRef.current + 1);
    }
  }, [setSize]);
  let returnData: SearchResult<T, THit>[] | undefined = undefined;
  let facets = undefined;
  let totalHits = undefined;
  let lastPageHasData = true;
  if (data && Array.isArray(data) && data.length > 0) {
    const idSet = new Set<string>();
    returnData = [];
    data.forEach((page) => {
      page.data.forEach((item) => {
        if (!idSet.has(item.id)) {
          returnData?.push(item);
          idSet.add(item.id);
        }
      });
    });
    facets = data[0].facets;
    totalHits = data[0].totalHits;
    lastPageHasData = data[data.length - 1].hasMore;
  }
  const loading = !!index && returnData === undefined;
  const error = swrError === 1 ? undefined : swrError;
  console.log(
    `useAlgoliaSearch/return index=${index} query=${queryOption?.query} data=${returnData?.length} loading=${loading} error=${error} hasMore=${lastPageHasData}`,
    facets,
  );

  return {
    data: returnData,
    error,
    facets,
    totalHits,
    hasMore: lastPageHasData,
    nextPage,
    loading,
  };
}
