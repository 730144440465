import { useEffect, useReducer } from "react";

import { TrackEvent, track } from "~/utils/analytics";
import { asyncCallWithTimeout } from "@sequoiacap/shared/utils/asyncCallWithTimeout";
import {
  getNumberOfCallsData,
  resetNumberOfCallsData,
} from "~/network/swr-firebase";
import afterFrame from "afterframe";
import useAsyncEffect from "@sequoiacap/client-shared/hooks/useAsyncEffect";

async function waitImageLoaded(image: HTMLImageElement) {
  return new Promise<number>((resolve) => {
    const src = image.src;
    if (!src) {
      resolve(0);
    }
    // filter out data urls
    const url = new URL(src);
    if (url.protocol === "data:") {
      resolve(0);
    }
    // filter out images from same origin
    if (url.origin === window.location.origin) {
      resolve(0);
    }
    const img = new window.Image();
    img.src = image.src;
    img.onload = function () {
      resolve(1);
    };
  });
}

function isChildOfInfinityScrollLoader(e: Element) {
  let element = e;
  while (element.parentElement) {
    if (element.getAttribute("data-infinite-scroll-loader")) {
      return true;
    }
    element = element.parentElement;
  }
  return false;
}

async function waitSkeletonUnload(skeleton: Element) {
  return asyncCallWithTimeout(
    new Promise<number>((resolve) => {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === "childList") {
            if (mutation.removedNodes.length > 0) resolve(1);
          }
        });
      });
      if (skeleton.parentElement) {
        // don't need to wait for infinite scroll loader
        if (isChildOfInfinityScrollLoader(skeleton)) {
          resolve(0);
        } else {
          observer.observe(skeleton.parentElement, {
            childList: true,
            subtree: true,
          });
        }
      } else {
        resolve(0);
      }
    }),
    2000,
  );
}

async function waitSkeletonsUnload() {
  for (let i = 0; i < 5; i++) {
    const skeletons = document.querySelectorAll('[aria-busy="true"]');
    try {
      console.log(
        "useTrackPageFullyLoaded/waitSkeletonsUnload/waiting for ",
        skeletons.length,
      );
      await Promise.all(
        Array.from(skeletons).map((skeleton) => waitSkeletonUnload(skeleton)),
      );
      console.log(
        "useTrackPageFullyLoaded/waitSkeletonsUnload/done waiting for",
        skeletons.length,
      );
      break;
    } catch (e) {
      console.log("useTrackPageFullyLoaded/waitSkeletonsUnload/error", e);
    }
  }
}

async function waitBackgroupImageLoaded(element: Element) {
  return new Promise<number>((resolve) => {
    const style = window.getComputedStyle(element);
    const backgroundImage = style.getPropertyValue("background-image");
    const imageUrl = backgroundImage
      .replace(/^url\(['"]?/, "")
      .replace(/['"]?\)$/, "");
    if (!imageUrl) {
      resolve(0);
    }
    // filter out data urls
    const url = new URL(imageUrl);
    if (url.protocol === "data:") {
      resolve(0);
    }
    // filter out images from same origin
    if (url.origin === window.location.origin) {
      resolve(0);
    }
    const img = new window.Image();
    img.src = imageUrl;
    img.onload = function () {
      resolve(1);
    };
  });
}

async function waitImagesLoaded() {
  // Get all the images on the page
  const images = document.querySelectorAll("img");
  const loaded = await Promise.all(
    Array.from(images).map((image) => waitImageLoaded(image)),
  );
  const loadedCount = loaded.reduce((a, b) => a + b, 0);

  const bgimages = document.querySelectorAll('[style*="background-image"]');
  const bgloaded = await Promise.all(
    Array.from(bgimages).map((image) => waitBackgroupImageLoaded(image)),
  );
  const bgloadedCount = bgloaded.reduce((a, b) => a + b, 0);
  return loadedCount + bgloadedCount;
}

async function afterFrameAsync() {
  return new Promise<void>((resolve) => {
    afterFrame(() => {
      resolve();
    });
  });
}

type ACTIONS =
  | { type: "SET_PATH"; path?: string; loading: boolean }
  | { type: "SET_LOADING_CHANGED"; loading: boolean }
  | { type: "SET_ASSET_LOADED"; imageCount: number }
  | { type: "SET_LOAD_TIMEOUT" };

type PageLoadState = {
  startTs: number;
  path?: string;
  loading: boolean;
  checkAssetStarted: boolean;
  assetLoaded: boolean;
};

function reducer(state: PageLoadState, action: ACTIONS): PageLoadState {
  switch (action.type) {
    case "SET_PATH":
      if (state.path !== action.path) {
        return {
          startTs: performance.now(),
          path: action.path,
          loading: action.loading,
          checkAssetStarted: !action.loading,
          assetLoaded: false,
        };
      }
      return state;
    case "SET_LOADING_CHANGED":
      if (state.loading === action.loading) return state;
      if (action.loading) {
        return {
          ...state,
          loading: true,
          checkAssetStarted: false,
          assetLoaded: false,
        };
      } else {
        return {
          ...state,
          loading: false,
          checkAssetStarted: true,
          assetLoaded: false,
        };
      }
    case "SET_ASSET_LOADED":
      const endTs = performance.now();
      const duration = endTs - state.startTs;
      const firestoreCallsInfo = getNumberOfCallsData();
      resetNumberOfCallsData();
      if (!window.frameElement) {
        console.log(
          `useTrackPageFullyLoaded/Page fully loaded pathname=${state.path} duration=${duration} imageCount=${action.imageCount} callsCount=${firestoreCallsInfo.requestsCount} maxInflightRequestCount=${firestoreCallsInfo.maxInFlightRequests}`,
        );
        track(TrackEvent.pageFullyLoaded, {
          path: state.path,
          duration,
          imageCount: action.imageCount,
          callsCount: firestoreCallsInfo.requestsCount,
          maxInflightRequestCount: firestoreCallsInfo.maxInFlightRequests,
        });
      } else {
        console.log(
          `useTrackPageFullyLoaded/IFrame Page fully loaded pathname=${state.path} duration=${duration} imageCount=${action.imageCount} callsCount=${firestoreCallsInfo.requestsCount} maxInflightRequestCount=${firestoreCallsInfo.maxInFlightRequests}`,
        );
      }

      return {
        ...state,
        assetLoaded: true,
      };
    case "SET_LOAD_TIMEOUT":
      return {
        ...state,
        assetLoaded: true,
      };
  }
}

// Don't track page fully loaded when pathname is undefined
export default function useTrackPageFullyLoaded(
  pathname: string | undefined,
  loading: boolean,
): boolean {
  const [state, dispatch] = useReducer(reducer, {
    startTs: performance.now(),
    path: pathname,
    loading: true,
    checkAssetStarted: false,
    assetLoaded: false,
  });

  useEffect(() => {
    dispatch({ type: "SET_PATH", path: pathname, loading });
    // Only update when pathname changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (pathname) {
      dispatch({ type: "SET_LOADING_CHANGED", loading });
    }
    // Only update when loading changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useAsyncEffect(
    async (isMounted) => {
      if (state.path && state.checkAssetStarted && !state.assetLoaded) {
        try {
          const imageCount = await asyncCallWithTimeout(
            (async () => {
              await afterFrameAsync();
              await waitSkeletonsUnload();
              return waitImagesLoaded();
            })(),
            30000,
          );

          if (!isMounted()) return;

          dispatch({ type: "SET_ASSET_LOADED", imageCount });
        } catch (e) {
          console.error(
            "useTrackPageFullyLoaded/Error waiting for images to load",
            e,
          );

          if (!isMounted()) return;
          dispatch({ type: "SET_LOAD_TIMEOUT" });
        }
      }
    },
    [state.checkAssetStarted, state.assetLoaded, pathname],
  );
  // console.log(`useTrackPageFullyLoaded`, state);

  return state.assetLoaded;
}
