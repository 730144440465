import { StorageTimestamp } from "./base/data-type";
import { asEnum } from "../utils/enum";
import FirestoreDataConverter from "./base/data-converter";

export const EXTENSION_EXPIRATION_DAYS = 14;

/**
 * Instance of an installation.
 */
export type LinkedinExtensionInstance = {
  status: "Installed" | "Uninstalled";
  clientId: string;
  version: string;

  /** Install date */
  createdAt: StorageTimestamp;

  /** Uninstall date  */
  deletedAt?: StorageTimestamp;
};

export type LinkedinExtensionAgreementStatus = {
  status: "NotShown" | "ShownOnce" | "Accepted";
  version: string;
  createdAt: StorageTimestamp;
  updatedAt: StorageTimestamp;
};

export type ExtensionVouch = {
  slug: string;
  comment: string;
  score?: string;
  wouldSupportInvestment?: boolean;
  createdAt: StorageTimestamp;
  updatedAt: StorageTimestamp;
};

export enum NetworkMinerStatus {
  /** We started generating the user's list but have not finished. User cannot take action yet and are waiting for us. */
  GeneratingList = "GeneratingList",

  /** List is generated, waiting for user to vouch */
  PendingVouches = "PendingVouches",

  /** All steps completed */
  Completed = "Completed",

  /** User hasn't completed the miner sheet for 2 years */
  Expired = "Expired",

  unknown = "unknown",
}

export enum ExtensionReadyState {
  // Error in header – We can't even a hint of data.
  NoLinkedinSlug = "NoLinkedinSlug",
  /**
   * The string value for this NotAFounder for historical reasons:
   * Extension used to be only for founders, but now open to more audiences.
   */
  InvalidAudience = "NotAFounder",
  Disabled = "Disabled", // Disabled for some reason in the backend

  // Error in body – We can show a hint of data, enticing the user to take an action.
  AgreementNotAccepted = "AgreementNotAccepted",
  ExtensionExpired = "ExtensionExpired",

  // No error
  Ok = "Ok",

  unknown = "unknown",
}

export type NetworkMinerStatusUpdate = {
  status: "scraping" | "error" | "sent" | "completed";
  hash: string;
  createdAt: StorageTimestamp;
  sentAt?: StorageTimestamp | null;
  targetLastViewedAt?: StorageTimestamp | null;
  targetCompletedAt?: StorageTimestamp | null;
  vouchedConnectionCount?: number;
  linkToReview?: string | null;
  ampersandId?: string | null;
};

export class LinkedinExtension {
  /** User id */
  id: string;

  /** LinkedIn Slug */
  linkedInSlug: string | null;

  /** */
  extensionReadyState: ExtensionReadyState;

  /** Id in the talent tool. Falsey if not enabled for Talent Tool (ATS)*/
  talentToolId?: string;

  /** History of changes to the agreement.
   *
   * Agreement must be accepted to use at all. */
  extensionAgreementStatus: LinkedinExtensionAgreementStatus[];

  /** User must be complete Network Miner (vouch list) within EXTENSION_EXPIRATION_DAYS days or else they lose the miner */
  networkMinerStatus?: NetworkMinerStatus;
  networkMinerStatusUpdatedAt?: StorageTimestamp;
  networkMinerLinkToReview?: string;
  networkMinerLastCompletedAt?: StorageTimestamp;
  networkMinerLastCompletedUpdate?: NetworkMinerStatusUpdate;

  /**
   * Who was vouched and what they vouched using the extension.
   */
  extensionVouches?: Record<string, ExtensionVouch>;

  /** When the user's use of the extension will expire. */
  expiresAt?: StorageTimestamp;

  /** The first installation */
  createdAt: StorageTimestamp;
  updatedAt?: StorageTimestamp;

  lastActionAt?: StorageTimestamp;

  constructor(
    id: string,
    linkedInSlug: string | null,
    extensionReadyState: ExtensionReadyState,
    extensionAgreementStatus: LinkedinExtensionAgreementStatus[],
    createdAt: StorageTimestamp,
    talentToolId?: string,
    networkMinerStatus?: NetworkMinerStatus,
    networkMinerStatusUpdatedAt?: StorageTimestamp,
    networkMinerLinkToReview?: string,
    networkMinerLastCompletedAt?: StorageTimestamp,
    networkMinerLastCompletedUpdate?: NetworkMinerStatusUpdate,
    extensionVouches?: Record<string, ExtensionVouch>,
    expiresAt?: StorageTimestamp,
    updatedAt?: StorageTimestamp,
    lastActionAt?: StorageTimestamp,
  ) {
    this.id = id;
    this.linkedInSlug = linkedInSlug;
    this.extensionReadyState = extensionReadyState;
    this.talentToolId = talentToolId;
    this.extensionAgreementStatus = extensionAgreementStatus;
    this.networkMinerStatus = networkMinerStatus;
    this.networkMinerStatusUpdatedAt = networkMinerStatusUpdatedAt;
    this.networkMinerLinkToReview = networkMinerLinkToReview;
    this.networkMinerLastCompletedAt = networkMinerLastCompletedAt;
    this.networkMinerLastCompletedUpdate = networkMinerLastCompletedUpdate;
    this.extensionVouches = extensionVouches;
    this.expiresAt = expiresAt;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.lastActionAt = lastActionAt;
  }
}

export const linkedinExtensionConverter: FirestoreDataConverter<LinkedinExtension> =
  {
    toFirestoreModel: function (linkedinExtension: LinkedinExtension) {
      return {
        id: linkedinExtension.id,
        linkedin_slug: linkedinExtension.linkedInSlug,
        extension_ready_state: linkedinExtension.extensionReadyState,
        talent_tool_id: linkedinExtension.talentToolId,
        extension_agreement_status:
          linkedinExtension.extensionAgreementStatus.map(
            (linkedinExtensionAgreementStatus) =>
              linkedinExtensionAgreementStatusConverter.toFirestoreModel(
                linkedinExtensionAgreementStatus,
              ),
          ),
        network_miner_status: linkedinExtension.networkMinerStatus,
        network_miner_status_updated_at:
          linkedinExtension.networkMinerStatusUpdatedAt,
        network_miner_link_to_review:
          linkedinExtension.networkMinerLinkToReview,
        network_miner_last_completed_at:
          linkedinExtension.networkMinerLastCompletedAt,
        network_miner_last_completed_update:
          linkedinExtension.networkMinerLastCompletedUpdate,
        extension_vouch: linkedinExtension.extensionVouches
          ? Object.entries(linkedinExtension.extensionVouches).reduce(
              (acc, [key, value]) => {
                acc[key] =
                  linkedinExtensionVouchConverter.toFirestoreModel(value);
                return acc;
              },
              {} as Record<string, any>,
            )
          : undefined,
        expires_at: linkedinExtension.expiresAt,
        created_at: linkedinExtension.createdAt,
        updated_at: linkedinExtension.updatedAt,
        last_action_at: linkedinExtension.lastActionAt,
      };
    },
    fromFirestoreModel: function (data): LinkedinExtension {
      const linkedinExtensionAgreementStatus =
        data.extension_agreement_status?.map(
          linkedinExtensionAgreementStatusConverter.fromFirestoreModel,
        ) ?? [];

      const extensionVouches = Object.entries(
        data.extension_vouch ?? {},
      ).reduce(
        (acc, [key, value]: [string, any]) => {
          acc[key] = linkedinExtensionVouchConverter.fromFirestoreModel(value);
          return acc;
        },
        {} as Record<string, ExtensionVouch>,
      );

      return new LinkedinExtension(
        data.id,
        data.linkedin_slug,
        asEnum(
          ExtensionReadyState,
          data.extension_ready_state,
          ExtensionReadyState.unknown,
        ),
        linkedinExtensionAgreementStatus,
        data.created_at,
        data.talent_tool_id,
        asEnum(
          NetworkMinerStatus,
          data.network_miner_status,
          NetworkMinerStatus.unknown,
        ),
        data.network_miner_status_updated_at,
        data.network_miner_link_to_review,
        data.network_miner_last_completed_at,
        data.network_miner_last_completed_update,
        extensionVouches,
        data.expires_at,
        data.updated_at,
        data.last_action_at,
      );
    },
  };

export const linkedinExtensionAgreementStatusConverter: FirestoreDataConverter<LinkedinExtensionAgreementStatus> =
  {
    toFirestoreModel: function (
      linkedinExtensionAgreementStatus: LinkedinExtensionAgreementStatus,
    ) {
      return {
        status: linkedinExtensionAgreementStatus.status,
        version: linkedinExtensionAgreementStatus.version,
        created_at: linkedinExtensionAgreementStatus.createdAt,
        updated_at: linkedinExtensionAgreementStatus.updatedAt,
      };
    },
    fromFirestoreModel: function (data): LinkedinExtensionAgreementStatus {
      return {
        status: data.status,
        version: data.version,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
      };
    },
  };

export const linkedinExtensionVouchConverter: FirestoreDataConverter<ExtensionVouch> =
  {
    toFirestoreModel: function (vouch: ExtensionVouch) {
      return {
        slug: vouch.slug,
        comment: vouch.comment,
        score: vouch.score,
        would_support_investment: vouch.wouldSupportInvestment,
        created_at: vouch.createdAt,
        updated_at: vouch.updatedAt,
      };
    },
    fromFirestoreModel: function (data): ExtensionVouch {
      return {
        slug: data.slug,
        comment: data.comment,
        score: data.score,
        wouldSupportInvestment: data.would_support_investment,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
      };
    },
  };
