import { TrackEvent, track } from "~/utils/analytics";
import { useEffect } from "react";
import URLUtils from "@sequoiacap/shared/utils/URLUtils";

function useTrackExternalLinkClick(): void {
  useEffect(() => {
    const handleClick = (mouseEvent: MouseEvent) => {
      const eventWillRedirect = mouseEvent.composedPath().filter((el) => {
        return el instanceof Node && el.nodeName === "A";
      });
      if (eventWillRedirect.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const href = (eventWillRedirect[0] as any).attributes.href?.value;
        const url = URLUtils.safeUrlWithBase(href, document.baseURI);
        if (
          url &&
          url.origin !== window.location.origin &&
          !url.hostname.endsWith("sequoiacap.com")
        ) {
          track(TrackEvent.visitingExternalSite, {
            site: url.toString(),
          });
        }
      }
    };

    document.addEventListener("click", handleClick, { capture: true });
    return () =>
      document.removeEventListener("click", handleClick, { capture: true });
  }, []);
}

export default useTrackExternalLinkClick;
