export const AMP_LI_EXT_PREFIX = "AMP_LI_EXT";
export enum AmpersandLinkedInExtensionRequestType {
  AuthStatus = `${AMP_LI_EXT_PREFIX}:auth_status`,
  SignIn = `${AMP_LI_EXT_PREFIX}:sign_in`,
  SignOut = `${AMP_LI_EXT_PREFIX}:sign_out`,
}

export type AmpersandLinkedInExtensionRequest = {
  type: AmpersandLinkedInExtensionRequestType;
};

export type AmpersandLinkedInExtensionAuthStatusRequest =
  AmpersandLinkedInExtensionRequest;
export type AmpersandLinkedInExtensionSignInRequest =
  AmpersandLinkedInExtensionRequest & {
    idToken: string;
  };
export type AmpersandLinkedInExtensionSignOutRequest =
  AmpersandLinkedInExtensionRequest;

export type AmpersandLinkedInExtensionRespond = {
  type: AmpersandLinkedInExtensionRequestType;
  error?: string;

  /** Id of the installed extension that is responding */
  clientId: string | undefined;
};

export type AmpersandLinkedInExtensionAuthStatusRespond =
  AmpersandLinkedInExtensionRespond & {
    signedIn: boolean;
    userId?: string;
  };
export type AmpersandLinkedInExtensionSignInRespond =
  AmpersandLinkedInExtensionRespond & {
    userId?: string;
  };
export type AmpersandLinkedInExtensionSignOutRespond =
  AmpersandLinkedInExtensionRespond & {
    signedIn: boolean;
  };

export const IFRAME_LI_EXT_MSG_PREFIX = "IFRAME_LI_EXT_MSG";
export enum IframeLinkedInExtensionMessageType {
  ExtensionAuthStatus = `${IFRAME_LI_EXT_MSG_PREFIX}:extension_auth_status`,
}
export enum ExtensionAuthStatusType {
  UserNotSignedIn = "UserNotSignedIn",
  UserNotAllowToUseExtension = "UserNotAllowToUseExtension",
  ExtensionSignedIn = "ExtensionSignedIn",
}

export type InlineCompanyInfo = {
  lirId: string;
  linkedinURL?: string;
  domain?: string;
  attributes?: string[];
  notableInvestors: string;
  fundraisingRounds: {
    date: Date;
    round: string;
    roundHumanReadable: string;
  }[];
};
