import {
  Attachment,
  DeletedPostRecord,
  Post,
  PostType,
  ServerTimestamp,
  convertAttachmentToFirebaseModel,
  deletedPostRecordDataConverter,
  groupConverter,
  postConverter,
} from "@sequoiacap/shared/models";
import {
  CloudFunctionName,
  FIREBASE_WRITE_TIMEOUT,
  ReadDocumentOptions,
  callCloudFunction,
  uploadFile,
  useReadDocument,
} from "./firebase/FirebaseAPI";
import { type ImageInfo } from "~/utils/image";
import { PaginatedResult, ReadDocumentReturnType } from "./firebase/types";
import {
  TrackErrorEvent,
  TrackEvent,
  track,
  trackError,
} from "~/utils/analytics";
import {
  arrayUnion,
  collection,
  deleteField,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { asyncCallWithTimeout } from "@sequoiacap/shared/utils/asyncCallWithTimeout";
import { fuego, mutateDocument } from "./swr-firebase";
import { getStorageFileDownloadURL } from "./swr-firebase/hooks/use-swr-storage";
import { omitUndefined } from "@sequoiacap/shared/utils/omitUndefined";
import { useSearchGetFeed } from "./search-api";
import getFirebaseDataConverter from "./firebase/firebase-data-converter";

export function useAPIGetPost(
  postId: string | null,
  options: ReadDocumentOptions<Post> = {},
): ReadDocumentReturnType<Post> {
  return useReadDocument(
    postId ? `post/${postId}` : null,
    postConverter,
    options,
  );
}

export function useAPIIsPostDeleted(
  postId: string | null | undefined,
  options: ReadDocumentOptions<DeletedPostRecord> = {},
): ReadDocumentReturnType<boolean> {
  const result = useReadDocument(
    postId ? `deleted_post_record/${postId}` : null,
    deletedPostRecordDataConverter,
    options,
  );
  return {
    ...result,
    data: !!result.data,
  };
}

export function useGetFeed(ready: boolean, limit = 10): PaginatedResult<Post> {
  return useSearchGetFeed(ready, limit);
}

export async function togglePostReaction(
  post: Post,
  reactionType: string,
  loggedInUserId: string,
): Promise<boolean> {
  console.log(
    `toggleReaction postId=${post.id} reactionType=${reactionType} loggedInUserId=${loggedInUserId}`,
  );

  let map: Record<string, Date> | null = null;
  switch (reactionType) {
    case "like": {
      if (!post.like) post.like = {};
      map = post.like;
      break;
    }
    case "helpful": {
      if (!post.helpful) post.helpful = {};
      map = post.helpful;
      break;
    }
    case "empathize": {
      if (!post.empathize) post.empathize = {};
      map = post.empathize;
      break;
    }
  }
  if (map) {
    if (map[loggedInUserId]) {
      delete map[loggedInUserId];
      await deletePostReaction(post.id, reactionType, loggedInUserId);
    } else {
      map[loggedInUserId] = new Date();
      await addPostReaction(post.id, reactionType, loggedInUserId);
    }
  }
  return true;
}

async function deletePostReaction(
  postId: string,
  reactionType: string,
  loggedInUserId: string,
): Promise<void> {
  const path = `post/${postId}`;
  try {
    await updateDoc(doc(fuego.db, path), {
      [`${reactionType}.${loggedInUserId}`]: deleteField(),
      updated_at: serverTimestamp(),
    });
  } catch (err) {
    console.error("deletePostReaction/error", err);
  }
  await mutateDocument(path, postConverter, true);
}

async function addPostReaction(
  postId: string,
  reactionType: string,
  loggedInUserId: string,
): Promise<void> {
  const path = `post/${postId}`;
  try {
    await setDoc(
      doc(fuego.db, path),
      {
        [reactionType]: {
          [loggedInUserId]: serverTimestamp(),
        },
        updated_at: serverTimestamp(),
      },
      {
        merge: true,
      },
    );
  } catch (err) {
    console.error("addPostReaction/error", err);
  }
  await mutateDocument(path, postConverter, true);
}

export type PendingPost = {
  title: string;
  body?: string;
  groupId: string;
  createdById: string;
  hashtag?: string[];
  mention?: Record<string, { id: string; name: string }>;
  attachment?: Record<string, Attachment>;
  pollOptions?: string[];
  attributes?: Record<string, string>;
};

export async function createPost(
  draftId: string,
  post: PendingPost,
  loggedInUserId: string,
): Promise<Post> {
  const path = `post/${draftId}`;
  const newPost = new Post(
    draftId,
    post.title,
    post.groupId,
    ServerTimestamp.create(),
    loggedInUserId,
    PostType.regular,
    post.body,
    post.mention,
    undefined,
    post.attachment,
    ServerTimestamp.create(),
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    post.hashtag,
    undefined,
    undefined,
  );
  try {
    track(TrackEvent.postCreating, {
      post_id: draftId,
      group_id: post.groupId,
      mention: post.mention ? Object.keys(post.mention) : undefined,
      hashtag: post.hashtag ? post.hashtag : undefined,
      attachment: post.attachment ? post.attachment.length : undefined,
      attributes: post.attributes,
    });
    await asyncCallWithTimeout(
      setDoc(
        doc(fuego.db, path).withConverter(
          getFirebaseDataConverter(postConverter),
        ),
        newPost,
      ),
      FIREBASE_WRITE_TIMEOUT,
    );
    track(TrackEvent.postCreated, {
      post_id: draftId,
      group_id: post.groupId,
      mention: post.mention ? Object.keys(post.mention) : undefined,
      hashtag: post.hashtag ? post.hashtag : undefined,
      attachment: post.attachment ? post.attachment.length : undefined,
      attributes: post.attributes,
    });
  } catch (err) {
    console.error("createPost/error", err);
    trackError(TrackErrorEvent.writePost, err, {
      post_id: draftId,
      group_id: post.groupId,
      error: `${err}`,
    });
    throw err;
  }
  const updated = await mutateDocument(path, postConverter, true);
  if (!updated) {
    throw new Error("Error saving post");
  }
  return updated;
}

export async function updatePost(
  currentPost: Post,
  updateData: PendingPost,
): Promise<Post> {
  const path = `post/${currentPost.id}`;
  const updateValue = {
    title: updateData.title,
    body: updateData.body,
    hashtag: updateData.hashtag,
    mention: omitUndefined(updateData.mention),
    attachment: convertAttachmentToFirebaseModel(updateData.attachment),
    updated_at: serverTimestamp(),
  };
  try {
    await asyncCallWithTimeout(
      updateDoc(doc(fuego.db, path), updateValue),
      FIREBASE_WRITE_TIMEOUT,
    );
    track(TrackEvent.postUpdated, {
      post_id: currentPost.id,
      group_id: currentPost.groupId,
      mention: currentPost.mention
        ? Object.keys(currentPost.mention)
        : undefined,
      hashtag: currentPost.hashtag ? currentPost.hashtag : undefined,
      attachment: currentPost.attachment
        ? currentPost.attachment.length
        : undefined,
      attributes: currentPost.attributes,
    });
  } catch (err) {
    console.error("updatePost/error", err);
    trackError(TrackErrorEvent.writePost, err, {
      post_id: currentPost.id,
      group_id: currentPost.groupId,
      error: `${err}`,
    });
    throw err;
  }
  // TODO poll
  const updated = await mutateDocument(path, postConverter, true);
  if (!updated) {
    throw new Error("Error saving post");
  }
  return updated;
}

export async function deletePost(post: Post): Promise<void> {
  await callCloudFunction(CloudFunctionName.deletePost, {
    id: post.id,
  });
  track(TrackEvent.postDeleted, { postId: post.id, groupId: post.groupId });

  const path = `post/${post.id}`;
  await mutateDocument(path, postConverter, true);

  if (post.groupId) {
    await mutateDocument(`group/${post.groupId}`, groupConverter, true);
  }
}

export async function logPostViewed(
  postId: string,
  userId: string,
): Promise<void> {
  const path = `post/${postId}`;
  try {
    await updateDoc(doc(fuego.db, path), {
      view: arrayUnion(userId),
      updated_at: serverTimestamp(),
    });
  } catch (err) {
    console.error("logPostViewed/error", err);
    throw err;
  }
  await mutateDocument(path, postConverter, false);
}

export function generatePostId(): string {
  return doc(collection(fuego.db, "post")).id;
}

export async function uploadImageForDraft(
  loggedInUserId: string,
  draftId: string,
  imageFileInfo: ImageInfo,
): Promise<Attachment> {
  const storagePath = await uploadFileForPost(
    loggedInUserId,
    draftId,
    imageFileInfo.file,
    `${imageFileInfo.hash}.jpg`,
  );
  const url = await getStorageFileDownloadURL(storagePath);

  return new Attachment(
    imageFileInfo.hash,
    imageFileInfo.file.name,
    storagePath,
    "image",
    url,
    imageFileInfo.width,
    imageFileInfo.height,
  );
}

function uploadFileForPost(
  loggedInUserId: string,
  postId: string,
  file: File,
  filename: string,
): Promise<string> {
  const path = `upload/${loggedInUserId}/post/${postId}/${filename}`;
  return uploadFile(path, file);
}
