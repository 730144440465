import { LibraryEntry, libraryEntryConverter } from "@sequoiacap/shared/models";
import { ReadDocumentReturnType, ReadQueryReturnType } from "./firebase/types";
import {
  collection,
  deleteDoc,
  doc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { exists } from "@sequoiacap/shared/utils/exists";
import { fuego, mutateDocument } from "./swr-firebase";
import { getLibraryAudienceKey } from "@sequoiacap/shared/utils/library";
import { useReadDocument } from "./firebase/FirebaseAPI";
import useSWR, { mutate } from "swr";
import useUserInfo from "./useUserInfo";

export function useGetLibraryAudienceKey(overrideAudienceKey?: string): {
  key?: string;
  loading: boolean;
} {
  const { audiences, loading } = useUserInfo();
  if (overrideAudienceKey) {
    return { key: overrideAudienceKey, loading: false };
  }
  if (loading) {
    return { loading: true };
  }
  return {
    key: getLibraryAudienceKey(audiences),
    loading,
  };
}

export function useGetLibraryEntryWithDependencies(
  id: string | null,
  overrideAudienceKey?: string,
): ReadDocumentReturnType<LibraryEntry> & { dependencies?: LibraryEntry[] } {
  const { key } = useGetLibraryAudienceKey(overrideAudienceKey);
  const { data: libraryEntry, error, loading } = useGetLibraryEntry(id, key);
  const { data: dependencies, loading: dependenciesLoading } =
    useGetLibraryEntries(libraryEntry?.dependencies, key);

  return {
    data: libraryEntry,
    error,
    loading: loading || dependenciesLoading,
    dependencies,
  };
}

export function useGetLibraryEntries(
  ids?: string[],
  overrideAudienceKey?: string,
): ReadQueryReturnType<LibraryEntry> {
  const { key } = useGetLibraryAudienceKey(overrideAudienceKey);
  const { data, error } = useSWR(
    ids !== undefined && key ? { ids, key } : null,
    async (k) => {
      const { ids: fetchIds, key: fetchAudienceKey } = k;
      const entries = await Promise.all(
        fetchIds.map(async (id) => {
          try {
            const entry = await mutateDocument(
              `library/${id}/v5/${fetchAudienceKey}`,
              libraryEntryConverter,
            );
            return entry;
          } catch (err) {
            console.error("useLoadDependencies/error", err);
            return undefined;
          }
        }),
      );
      return entries.filter(exists);
    },
  );
  return {
    data,
    error,
    loading: !error && data === undefined && ids !== undefined,
  };
}

export function useGetLibraryEntry(
  id: string | null,
  overrideAudienceKey?: string,
): ReadDocumentReturnType<LibraryEntry> {
  const { key } = useGetLibraryAudienceKey(overrideAudienceKey);
  const path = id && key ? `library/${id}/v5/${key}` : null;

  return useReadDocument(path, libraryEntryConverter, {});
}

export async function deleteLibraryEntryReaction(
  entryId: string,
): Promise<void> {
  if (!entryId) {
    return;
  }
  const path = `library_reaction/${entryId}`;
  try {
    await deleteDoc(doc(fuego.db, path));
  } catch (err) {
    console.error("deleteLibraryEntryReaction/error", err);
  }
  await mutate(path);
}

export async function addLibraryEntryReaction(
  entryId: string,
  reactionType: string,
  loggedInUserId: string,
  reactionId?: string,
): Promise<string> {
  const id = reactionId
    ? reactionId
    : doc(collection(fuego.db, "library_reaction")).id;
  const path = `library_reaction/${id}`;
  try {
    await setDoc(doc(fuego.db, path), {
      id,
      library_id: entryId,
      created_by_id: loggedInUserId,
      created_at: serverTimestamp(),
      reaction_type: reactionType,
    });
  } catch (err) {
    console.error("addLibraryEntryReaction/error", err);
    throw err;
  }
  return id;
}
